/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* Gradient styles */
  --gradient-color-primary: #9a9c9ef6;
  --gradient-color-secondary: #9a9c9ef6;

  /** primary **/
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--gradient-color-primary) 3.56%,
    var(--gradient-color-secondary) 97.13%
  );
  --ion-color-primary: #9a9c9ef6;
  --ion-color-primary-rgb: 255, 167, 36;
  --ion-color-primary-contrast: #0a0d15;
  --ion-color-primary-contrast-rgb: 10, 13, 21;
  --ion-color-primary-shade: #e09320;
  --ion-color-primary-tint: #ffb03a;
  --ion-primary-border-color: #9a9c9ef6;

  --background-ctn-gradient: linear-gradient(
    90deg,
    #9a9c9ef6 0%,
    rgba(4, 31, 89, 0) 100%
  );
  --markets-table-background: #eff4ff;

  /** secondary **/
  --ion-color-secondary: #37446e;
  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #303c61;
  --ion-color-secondary-tint: #4b577d;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #34a300;
  --ion-color-success-rgb: 52, 163, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e05656;
  --ion-color-danger-tint: #ff7272;
  --ion-color-lost: #cf0a13;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Sports List Navbar Colors **/
  --ion-nav-card-background: var(--ion-color-primary);
  --ion-nav-card-text: #00061a;

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;
  --ion-header-background: #000000;
  --ion-header-link-color: rgba(255, 255, 255, 0.6);

  /** Back and Lay **/
  --back-odd-background: #6bc0ff;
  --back-odd-background-light: #89bce2;
  --back-odd-background-rgb: 151, 211, 255;
  --lay-odd-background: #ff8282;
  --lay-odd-background-rgb: 255, 173, 173;
  --lay-odd-background-light: rgba(255, 130, 130, 0.5);
  --premium-odd-background: #72e3a0;
  --premium-tab-color: #fff;

  /** Table colour **/
  --table-headers-light: #fff;
  --headers-font-family: 'Jost';

  /* Odds info icons */
  --market-info-icons: #fff;

  /*
   * White Label variables
   * ---------------------
   */
  --odd-btn-animation-color: #9a9c9ef6;
  --footer-text-color: #6a7394;
  --nav-list-live-icon-color: #ff0000;

  /** Header Logo Sizes **/
  --logo-xl-width: 176px;
  --logo-xl-height: 53px;
  --logo-lg-width: 176px;
  --logo-lg-height: 53px;
  --logo-md-width: 110px;
  --logo-md-height: 53px;
  --logo-sm-width: 92px;
  --logo-sm-height: 53px;
  --logo-xs-width: 92px;
  --logo-xs-height: 53px;

  /* Login Page logo */
  --login-logo-xl-width: 240px;
  --login-logo-xl-height: 64px;
  --login-logo-sm-width: 156px;
  --login-logo-sm-height: 68px;

  /* Sidebar links */
  --sidebar-active-link-color: #212121;

  /* Dashboard colors */
  --dashboard-ctn-background: #212121;
  --dashboard-provider-background: #9a9c9ef6;
  --dashboard-text-border-btm: linear-gradient(
    90deg,
    #9a9c9ef6 0%,
    rgba(255, 167, 36, 0) 100%
  );
  --dashboard-live-game-dc-card-ctn: linear-gradient(
    180deg,
    #000000 0%,
    #212121 100%
  );
  --linear-color-change: #9a9c9ef6;
  --home-unerline-linear-color-change: #9a9c9ef6;
  --events-table-broder-col: #00061a;
  --enabled-market-btn-col: linear-gradient(
    45deg,
    #000000 48.44%,
    #212121 48.45%
  );

  /* Footer Background */
  --footer-ctn-background: #000000;
  --footer-vertical-gradient: linear-gradient(180deg, #000000 0%, #212121 100%);
}

/*
 * Light Colors
 * ------------------
 */
.ios body,
.md body {
  --ion-background: #000000;
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-color: #fff;

  --ion-background-secondary: #212121;
  --ion-background-tertiary: #212121;

  --linear-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --linear-gradient-background-2: #000000;

  --accoridan-summary-background: #000000;

  --table-header-background: linear-gradient(180deg, #000000 0%, #212121 100%);

  --webkit-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --webkit-gradient-background-2: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 117.02%
  );

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 20, 61;
  --ion-text-light: #ffffff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  --ion-text-color-contrast: var(--ion-text-dark);
  --ion-text-color-contrast-rgb: var(--ion-text-dark-rgb);

  /** Card Variables**/
  --ion-card-background: #e4e8ed;
  --ion-card-text-color: #000000;
  --ion-card-background-contrast: #ffffff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /** Popover Variables**/
  --ion-popover-background: #000000;
  --ion-popover--hover-background: #212121;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #ffffff;
  --accordion-summary-root-expanded-background: #212121;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #ffffff;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-element-background: var(--ion-popover--hover-background);
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-popover--hover-background);

  /** Dialog Styles **/
  --dialog-header-background: #212121;
  --dialog-content-background: #e4e8ed;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #ffffff;
  --date-picker-header-button-background: #e4e8ed;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #00143d;

  /** Input Field Styles **/
  --input-background-color: #212121;
  --input-text-color: #0a0d15;

  /** Reports style **/
  --reports-bg-color: #ffffff;
  --reports-title-color: var(--ion-color-primary);
  --filter-controls-border: rgba(180, 180, 180, 1);

  /* Odds info icons */
  --market-info-icons: #fff;

  /* Scorecard */
  --score-card-details-color: var(--ion-color-primary);
  --score-card-background-color: #000000;

  /* signup card */
  --signup-card-background-color: #212121;
  --signup-font-color: #fff;

  /* notification background color */
  --notification-background-col: #9a9c9ef6;

  /* Markets Accordian*/
  --accordian-headers-color: #fff;
}

.ios body {
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}

ion-title {
  --color: #ffffff;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
